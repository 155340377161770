import React from "react";

function Explanation() {
  return (
    <div>
      <div className="container courses_margin">
        <h3 style={{color:"#833988"}}>صفحة الشرح :</h3>
        <hr className="mt-4"style={{color:"#F57D20"}}/>
        <h5 style={{color:"#833988"}}>السؤال الاول : ما معنى ال SEO ؟</h5>
        <p style={{color:"#833988"}}>
          الشرح : السيو هي معنا تحسبن محركات البحث من اجل زيادة زوار الموقع{" "}
        </p>
        <hr className="mt-5" style={{color:"#F57D20"}}/>

      </div>
    </div>
  );
}

export default Explanation;
