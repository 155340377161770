import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Draggable from "react-draggable";

function Quiz() {
  const [question, setQuestion] = useState("");
  const [blanks, setBlanks] = useState([]);

  //   const [hasMounted, setHasMounted] = useState(false);

  //   useEffect(() => {
  //     setHasMounted(true); // Set to true after the initial render
  //   }, []);

  useEffect(() => {
    // if (!hasMounted) return;

    const fetchData = async () => {
      const apiData = {
        question:
          " السؤال الثالث : تعتبر ال ______________واحدة من اهم الادوات التي تساعد في عملية تسحين نتائج البحث وهذا باستخدام _______________ كما ان لديها العديد من الميزات والخصائص مثل : 1____________ 2___________",
        blanks: [
          {
            sentence:
              "<span class='drop-zone' data-accept='fitz'></span>, a native of St Paul, Minnesota, finished four novels.",
            accept: " الادوات",
          },
          {
            sentence:
              "<span class='drop-zone' data-accept='carraway'></span> attended Yale with <span class='drop-zone' data-accept='buchanan'></span>.",
            accept: ["الكلمات المفتاحية"],
          },
          {
            sentence:
              "George Wilson owns a garage in <span class='drop-zone' data-accept='ashes'></span> where Tom brings Nick for a party.",
            accept: "الميزة الاولى",
          },
          {
            sentence:
              "Jay Gatsby, the Buchanans, and Nick all make <span class='drop-zone' data-accept='westegg'></span> their home.",
            accept: "الميزة الثانية",
          },
        ],
      };
      setQuestion(apiData.question);
      setBlanks(apiData.blanks.map((b) => ({ ...b, answer: null })));
    };
    fetchData();
  }, []); //hasMounted // Only fetch data if the component has mounted

  useEffect(() => {
    window.scrollTo(0, 0);
    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(() => {
        // Resize logic
      });
    });

    resizeObserver.observe(document.body); // or the specific element

    return () => resizeObserver.disconnect();
  }, []);
  //   useEffect(() => {
  //     setTimeout(() => {
  //       const resizeObserver = new ResizeObserver(() => {
  //         // Your resize logic
  //       });

  //       resizeObserver.observe(document.body); // or any specific element

  //       return () => resizeObserver.disconnect();
  //     }, 0); // Adjust timeout duration if needed
  //   }, []);

  const handleDrop = (answer, blankIndex) => {
    setBlanks((prev) => {
      const newBlanks = [...prev];
      newBlanks[blankIndex].answer = answer;
      return newBlanks;
    });
  };

  return (
    <>
      {" "}
      <div style={{ overflow: "hidden" }}>
        <div className="container courses_margin">
          <h4 style={{ color: "#833988", marginBottom: "20px" }}>
            الموضوع الاول
          </h4>
          <hr className="mt-4" style={{ color: "#F57D20" }} />
          {/* RADIO BUTTON */}
          <div>
            <h5 style={{ color: "#833988", marginBottom: "20px" }}>
              السؤال الاول : ما معنى ال SEO ؟
            </h5>
            <div className="d-flex mb-3">
              <Form.Check
                type="radio"
                aria-label="radio 1"
                name="seoOptions"
                className="ms-2"
              />
              <span htmlFor="">
                السيو هي معنا تحسبن محركات البحث من اجل زيادة زوار الموقع{" "}
              </span>
            </div>
            <div className="d-flex">
              <Form.Check
                type="radio"
                aria-label="radio 2"
                name="seoOptions"
                className="ms-2"
              />
              <span htmlFor="">
                السيو هي معنا تحسبن محركات البحث من اجل زيادة زوار الموقع{" "}
              </span>
            </div>
            <hr className="mt-4" style={{ color: "#F57D20" }} />
          </div>
          {/* TEXT AREA */}
          <div>
            <h5 style={{ color: "#833988", marginBottom: "20px" }}>
              السؤال الثاني : ما معنى ال SEO ؟
            </h5>
            <p style={{ color: "#F57D20", fontWeight: "bold" }}>
              ادخل الاجابة :
            </p>
            <textarea name="" id="" rows={15} className="w-100"></textarea>
            <hr className="mt-4" style={{ color: "#F57D20" }} />
          </div>
          {/* DRAG DROP */}
          <div>
            <h5
              style={{ color: "#833988", marginBottom: "20px" }}
              className="question-description"
            >
              {question}
            </h5>
            <div
              className="answers"
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {/* <ol> */}
              {blanks.map((b, blankIndex) => (
                <Draggable
                  key={blankIndex}
                  onStop={(e, data) => handleDrop(b.accept, blankIndex)}
                >
                  {/* <li className="option" data-target={b.accept}>
      {b.accept}
      </li> */}
                  <button
                    style={{
                      backgroundColor: "#833988",
                      border: "none",
                      borderRadius: "20px",
                      padding: "5px",
                      color: "#fff",
                      marginLeft: "5px",
                    }}
                    data-target={b.accept}
                  >
                    {b.accept}
                  </button>
                </Draggable>
              ))}
              {/* </ol> */}
            </div>
            <hr className="mt-4" style={{ color: "#F57D20" }} />
          </div>
          {/* CHECK BOX */}
          <div>
            <h5 style={{ color: "#833988", marginBottom: "20px" }}>
              السؤال الاول : ما معنى ال SEO ؟
            </h5>
            <div className="d-flex mb-3">
              <Form.Check
                aria-label="radio 1"
                name="seoOptions"
                className="ms-2"
              />
              <span htmlFor="">
                السيو هي معنا تحسبن محركات البحث من اجل زيادة زوار الموقع{" "}
              </span>
            </div>
            <div className="d-flex">
              <Form.Check
                aria-label="radio 2"
                name="seoOptions"
                className="ms-2"
              />
              <span htmlFor="">
                السيو هي معنا تحسبن محركات البحث من اجل زيادة زوار الموقع{" "}
              </span>
            </div>
            <hr className="mt-4" style={{ color: "#F57D20" }} />
          </div>

          {/* ANSWERS */}
          <h2 style={{ color: "#833988", marginBottom: "20px" }}>الاجابات</h2>
          <h5 style={{ color: "#833988", marginBottom: "20px" }}>
            السؤال الاول : أ{" "}
          </h5>
          <p>
            الشرح : السيو هي معنا تحسبن محركات البحث من اجل زيادة زوار الموقع{" "}
          </p>
          <h5 style={{ color: "#833988", marginBottom: "20px" }}>
            السؤال الثاني : ب{" "}
          </h5>
          <p>
            الشرح : السيو هي معنا تحسبن محركات البحث من اجل زيادة زوار الموقع{" "}
          </p>
          <h5 style={{ color: "#833988", marginBottom: "20px" }}>
            السؤال الثالث :ج{" "}
          </h5>
          <p>
            الشرح : السيو هي معنا تحسبن محركات البحث من اجل زيادة زوار الموقع{" "}
          </p>
        </div>
      </div>
    </>
  );
}

export default Quiz;
