import React, { useState, useEffect, useRef, useContext } from "react";
import "../Css/courseDetails.css";
import { Link, useParams } from "react-router-dom";
import Tabs from "../Pages/Tabs.js";
import Tab from "../Pages/Tab.js";
import { IoIosArrowDown } from "react-icons/io";
import axios from "axios";
import { UserContext } from "../UserContext.js";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "../Css/courses.css";
import MiniPopUpLogin from "../components/MiniPopUpLogin.js";
import MiniPopUpConfirm from "../components/MiniPopUpConfirm.js";
import ReadMoreReact from "read-more-react";
import { API_URL } from "../App.js";
import quesicon from "../assets/wallet.png";
import VideoModel from "../components/VideoModel.js";
function QuestionDetails() {
  const { id } = useParams();
  const [courseDetails, setCourseDetails] = useState([]);
  const [videosData, setVideosData] = useState([]);
  const [studentCount, setStudentCount] = useState(0);
  const [lessonCounts, setLessonCounts] = useState(0);
  const [courseId, setcourseId] = useState(null);
  const [show, setShow] = useState(false); // State for controlling modal visibility
  const { user } = useContext(UserContext);
  const { userId } = user;
  const [studentName, setStudentName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [message, setMessage] = useState("");
  const [couponError, setCouponError] = useState("");
  const [studentNameError, setStudentNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [showLoginPopup, setShowLoginPopup] = useState(false); // State for controlling MiniPopUpLogin visibility
  const [ShowPopupConf, setShowPopupConf] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const [course_users, setCourse_users] = useState([]);
  const [approvedUser, setApprovedUser] = useState(null);

  const title_popup = "تسجيل الدخول";
  const description_popup = "لشراء قسم يجب تسجيل الدخول";
  const title_popup_confirm = " تنبيه";
  const description_popup_confirm = "تمت العملية ، طلبك قيد الأنتظار";

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCourseDetails();
  }, []);

  useEffect(() => {
    const fetchVideosData = async () => {
      try {
        const response = await fetch(`${API_URL}/Courses/videos/${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch video details");
        }
        const data = await response.json();
        setVideosData(data);
      } catch (error) {
        console.error("Error fetching video details:", error);
      }
    };
    fetchVideosData();
  }, [id]);

  const [expandedItemId, setExpandedItemId] = useState(null);
  const handleClick = (itemId) => {
    setExpandedItemId(expandedItemId === itemId ? null : itemId);
  };

  useEffect(() => {
    const fetchStudentCount = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/Courses/users-counts/${courseId}`
        );
        const data = response.data;
        if (data && data.student_count !== undefined) {
          setStudentCount(data.student_count); // Set the course count from the response
        }
      } catch (error) {
        console.error("Error fetching course count:", error);
      }
    };

    if (courseId) {
      fetchStudentCount();
    }
  }, [courseId]);
  const fetchCourseDetails = async () => {
    try {
      const response = await fetch(`${API_URL}/Courses/details/${id}`);
      const data = await response.json();
      setCourseDetails(data);
      // settecherId(data[0].teacher_id);
      if (data && data[0]) {
        setcourseId(data[0].id);
      }
    } catch (error) {
      console.error("Error fetching course details:", error);
    }
  };
  useEffect(() => {
    const fetchLessonCounts = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/Courses/lesson-counts/${courseId}`
        );
        const data = response.data;
        if (data.length > 0) {
          setLessonCounts(data[0].lesson_count); // Set the lesson count from the first item in the array
        } else {
          setLessonCounts(0); // In case no lessons are found
        }
      } catch (error) {
        console.error("Error fetching course count:", error);
      }
    };

    if (courseId) {
      fetchLessonCounts();
    }
  }, [courseId]);

  const handleClose = () => {
    setShow(false);
    setShowPopupVideo(false);
  };

  const handleClosePopup = () => {
    setShowLoginPopup(false);
  };

  const handleClosePopupConf = () => {
    setShowPopupConf(false);
  };

  const validateCouponCode = async (code) => {
    try {
      const response = await fetch(`${API_URL}/PaymentsCourse/validate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ coupon_code: couponCode, course_id: courseId }),
      });

      const data = await response.json();

      if (!response.ok) {
        return data.error || "Invalid coupon code";
      }

      // Check if the coupon type is not 'course'
      if (data.couponType !== "course") {
        return "رمز الكوبون غير صالح"; // Return the error message
      }

      return ""; // No error
    } catch (error) {
      console.error("Error checking coupon code:", error);
      return "Invalid coupon code";
    }
  };

  const handleSubmitPay = async (event) => {
    event.preventDefault();

    // Validate all fields
    const errors = {};

    if (!studentName) errors.studentName = "اسم الطالب مطلوب";
    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))
      errors.email = "البريد الإلكتروني غير صحيح";
    if (!address) errors.address = "مكان السكن مطلوب";
    if (!phone || !/^\d+$/.test(phone)) errors.phone = "رقم الهاتف غير صحيح";

    // Custom validation for coupon code
    const couponError = await validateCouponCode(couponCode); // Await the result of validateCouponCode
    if (!couponCode || couponError) {
      errors.couponCode = couponError || "رقم الكوبون غير صالح";
    }
    // Set errors and return if any
    setStudentNameError(errors.studentName || "");
    setEmailError(errors.email || "");
    setAddressError(errors.address || "");
    setPhoneError(errors.phone || "");

    setCouponError(errors.couponCode || "");

    if (Object.keys(errors).length > 0) {
      return;
    }
    setCouponError("");
    setStudentNameError("");
    setEmailError("");
    setAddressError("");
    setPhoneError("");
    const userId = localStorage.getItem("id"); // Retrieve user_id from local storage

    if (!userId) {
      setMessage("User ID not found. Please log in.");
      handleClose();
      setShowLoginPopup(true);
      return;
    }
    try {
      const response = await axios.post(`${API_URL}/PaymentsCourse/courses`, {
        student_name: studentName,
        email: email,
        address: address,
        phone: phone,
        coupon_code: couponCode,
        course_id: courseId,
        user_id: userId,
      });
      setMessage("Request was successful!");
      handleClose();
      setSmShow(true);
      setShowPopupConf(true);
      // Clear recorded courses
      setStudentName("");
      setEmail("");
      setAddress("");
      setAddress("");
      setPhone("");
      setCouponCode("");
    } catch (error) {
      console.error(
        "Error submitting form:",
        error.response ? error.response.data : error.message
      );

      if (
        error.response &&
        error.response.data &&
        error.response.data.error === "Invalid coupon code"
      ) {
        setCouponError("رقم الكوبون غير صالح");
      } else {
        setMessage("There was an error with your submission.");
      }
    }
  };

  const handleShow = () => setShow(true);
  useEffect(() => {
    const fetchCourseUsers = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/PaymentsDepartments/getallcourseusers`
        );
        setCourse_users(response.data);
      } catch (error) {
        console.error("Failed to fetch course users:", error);
      }
    };

    if (courseId) {
      fetchCourseUsers();
    }
  }, [courseId]);

  // Determine if the user is approved
  useEffect(() => {
    if (course_users.length > 0 && userId && courseId) {
      // Check if the user is approved for the current course

      const user_courses = course_users.find(
        (user) =>
          user.payment_status === "approved" &&
          user.user_id == userId &&
          user.course_id === courseId
      );
      setApprovedUser(user_courses);
    }
  }, [course_users, userId, courseId]); // Include `course` in dependency array if `course.expiration_date` is used

  const [showPopupVideo, setShowPopupVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const handleVideoClick = () => {
    setVideoUrl("https://www.youtube.com/embed/dQw4w9WgXcQ"); // Example video URL
    setShowPopupVideo(true);
  };

  return (
    <>
      {/* header of course details */}
      {courseDetails.map((course) => (
        <div
          className="container text-center cont_course_details"
          key={course.id}
        >
          <div className="row ">
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center">
              <img
                src={`https://res.cloudinary.com/dqimsdiht/${course.img}`}
                alt="coursedetails"
                className="img-fluid img_coursedetails"
                loading="lazy"
              />{" "}
            </div>
            <div className="col-lg-6 col-md-6 cl-sm-12 ">
              <div className="dep_teacher_coursedetails ">
                <p className="dep_coursedetaile">{course.Department?.title}</p>
                <p className="teacher_coursedetails">
                  {course.teacher?.teacher_name}
                </p>
              </div>
              <h1 className="title_coursedetails">{course.subject_name}</h1>
              <div className="d-flex justify-content-around ">
                <div className="d-flex">
                  <i
                    class="fa-solid fa-graduation-cap card_icon"
                    style={{ color: "#F57D20" }}
                  ></i>

                  <p className="details_courses_card"> {studentCount} طالب </p>
                </div>
                <div className="d-flex">
                  <i
                    class="fa-solid fa-file card_icon"
                    style={{ color: "#F57D20" }}
                  ></i>
                  <p className="details_courses_card "> {lessonCounts} درس</p>
                </div>

                {course.total_video_duration !== "0h 0m 0s" ? (
                  <div className="d-flex">
                    <i
                      className="fa-solid fa-clock card_icon"
                      style={{ color: "#F57D20" }}
                    ></i>
                    <p className="details_courses_card">
                      {course.total_video_duration}
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ))}
      {/* End header of course details */}

      <section className="margin_section">
        <div className="container text-center">
          <div className="row">
            <div
              className="col-lg-5 col-md-12 col-sm-12"
            >
              {/* <Video/> */}

              {videosData.length > 0 && (
                <div className="video_cont">
                  <div className="video_wrapper">
                      <div>
                        <video
                          controls
                          controlsList="nodownload"
                          className="video_play"
                          preload="metadata"
                        >
                          <source
                            src={`https://res.cloudinary.com/dqimsdiht/video/upload/${videosData[0].course.defaultvideo}`} // Assuming first video is default
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                        {approvedUser ? (
                          <>
                            <div className="d-flex justify-content-center">
                              <div>
                                <h2 className="title_after_purchase">
                                  {videosData[0].subject_name}
                                </h2>
                                <h3 className="teachar_after_purchase">
                                  {videosData[0].course.teacher.teacher_name}
                                </h3>
                              </div>
                            </div>
                            <div className="d-flex justify-content-center">
                              <Link
                                target="blankk"
                                to={`https://res.cloudinary.com/dqimsdiht/image/upload/v1736931832/${videosData[0].course.file_book}`}
                              >
                                <button
                                  style={{
                                    backgroundColor: "#833988",
                                    border: "none",
                                    borderRadius: "25px",
                                    color: "#fff",
                                    fontSize: "12px",
                                    height: "35px",
                                  }}
                                  className="px-3"
                                >
                                  {" "}
                                  <i
                                    className="fa-solid fa-download px-2"
                                    style={{ color: "#ffffff" }}
                                  ></i>
                                  تحميل
                                </button>
                              </Link>
                            </div>
                          </>
                        ) : (
                          <div>
                            <div className="d-flex justify-content-center">
                              <p className="after_price_coursedetails">
                                {videosData[0].course.after_offer} دينار
                              </p>
                              <p className="before_price_coursedetails">
                                {videosData[0].course.before_offer} دينار
                              </p>
                            </div>
                            <button
                              className="purchase_now_coursedetails"
                              onClick={handleShow}
                            >
                              شراء الان
                            </button>
                          </div>
                        )}

                        {/* Modal */}
                        <Modal show={show} onHide={handleClose} dir="rtl">
                          <Modal.Title className="modal_title">
                            شراء مادة
                          </Modal.Title>
                          <Modal.Body>
                            <Form id="buyDepartmentForm">
                              <Form.Group className="mb-3">
                                <Form.Label className="text_field">
                                  اسم الطالب
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className={`input_filed_modal ${
                                    studentNameError ? "border-danger" : ""
                                  }`}
                                  value={studentName}
                                  onChange={(e) =>
                                    setStudentName(e.target.value)
                                  }
                                  required
                                />
                                {studentNameError && (
                                  <Form.Text className="text-danger">
                                    {studentNameError}
                                  </Form.Text>
                                )}
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label className="text_field text-center">
                                  الأيميل
                                </Form.Label>
                                <Form.Control
                                  type="email"
                                  className={`input_filed_modal ${
                                    emailError ? "border-danger" : ""
                                  }`}
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  required
                                />
                                {emailError && (
                                  <Form.Text className="text-danger">
                                    {emailError}
                                  </Form.Text>
                                )}
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label className="text_field text-center">
                                  مكان السكن
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className={`input_filed_modal ${
                                    addressError ? "border-danger" : ""
                                  }`}
                                  value={address}
                                  onChange={(e) => setAddress(e.target.value)}
                                  required
                                />
                                {addressError && (
                                  <Form.Text className="text-danger">
                                    {addressError}
                                  </Form.Text>
                                )}
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label className="text_field text-center">
                                  رقم الهاتف
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className={`input_filed_modal ${
                                    phoneError ? "border-danger" : ""
                                  }`}
                                  value={phone}
                                  onChange={(e) => setPhone(e.target.value)}
                                  required
                                />
                                {phoneError && (
                                  <Form.Text className="text-danger">
                                    {phoneError}
                                  </Form.Text>
                                )}
                              </Form.Group>

                              <Form.Group className="mb-3">
                                <Form.Label className="text_field text-center">
                                  الكوبون
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className={`input_filed_modal ${
                                    couponError ? "border-danger" : ""
                                  }`}
                                  value={couponCode}
                                  onChange={(e) =>
                                    setCouponCode(e.target.value)
                                  }
                                  required
                                />
                                {couponError && (
                                  <Form.Text className="text-danger">
                                    {couponError}
                                  </Form.Text>
                                )}
                              </Form.Group>
                            </Form>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              type="submit"
                              onClick={handleSubmitPay}
                              form="buyDepartmentForm"
                              className="buy_department_btn"
                            >
                              شراء الآن
                            </Button>
                          </Modal.Footer>
                        </Modal>
                        {/* End Modal */}
                      </div>
                  </div>
                </div>
              )}

              {/*End video  */}
            </div>
            {courseDetails.map((course) => (
              <div
                className="col-lg-7 col-md-12 col-sm-12 col_tabs_coursedetails"
                key={course.id}
              >
                <Tabs>
                  <Tab title="عن المادة">
                    <div className="description_coursedetails">
                      <ReadMoreReact
                        // text={course.descr}
                        text={
                          "عصرنا الرقمي، تحولت التكنولوجيا إلى عنصر أساسي في حياتنا اليومية، ومعها، ظهر التعليم عن بُعد كوسيلة مثالية لتكميل النظام التعليمي التقليدي. هذه الطريقة الجديدة تمكن الطلاب من فهم المفاهيم المعقدة بسهولة أكبر"
                        }
                        min={200}
                        ideal={300}
                        max={500}
                        readMoreText="اقرأ المزيد"
                        readMoreClassName="read-more-button"
                      />
                    </div>
                    <div>
                      <div className="container text-center">
                        {videosData.map((item, index) => (
                          <div
                            className="row "
                            key={item.id}
                            style={{
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                              margin: "1vh",
                              listStyleType: "none",
                            }}
                          >
                            <div className={`col-lg-6 col-md-6 col-sm-12`}>
                              <div className="d-flex align-items-center pt-2">
                                <li style={{ cursor: "pointer" }}>
                                  {item.title}
                                </li>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <div className="d-flex justify-content-evenly">
                                <div className="d-flex">
                                  <i
                                    className="fa-solid fa-file card_icon"
                                    style={{ color: "#F57D20" }}
                                  ></i>
                                  <p className="details_courses_card">1 درس</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Tab>
                  <Tab title="شراء الان">
                    <div className="description_coursedetails">
                      <ReadMoreReact />
                    </div>
                    <div>
                      <button
                        className="purchase_now_coursedetails mt-3"
                        onClick={handleShow}
                      >
                        شراء الان
                      </button>
                    </div>
                  </Tab>
                  {approvedUser ? (
                    <Tab title="تست بانك">
                      <div>
                        <p className="description_coursedetails">
                          {course.descr}
                        </p>
                        <div className="container text-center">
                          {videosData.map((item, index) => (
                            <div
                              className="row "
                              key={item.id}
                              style={{
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                                margin: "1vh",
                                listStyleType: "none",
                              }}
                              onClick={() => handleClick(item.id)}
                            >
                              <div
                                className={`col-lg-6 col-md-6 col-sm-12 ${
                                  expandedItemId === item.id ? "mb-3" : ""
                                }`}
                              >
                                <div className="d-flex align-items-center p-3">
                                  <IoIosArrowDown />
                                  <li style={{ cursor: "pointer" }}>
                                    {/* {item.title} */}الموضوع الاول
                                  </li>
                                </div>
                              </div>
                              {expandedItemId === item.id && (
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex">
                                    <img
                                      src={quesicon}
                                      alt="quesicon"
                                      height={"25px"}
                                      width={"25px"}
                                    />
                                    <p>السؤال الاول</p>
                                  </div>
                                  <div className="d-flex">
                                    <button
                                      className="show_video_btn"
                                      style={{backgroundColor:"#833988",color:"#fff",borderRadius:"20px",border:"none",height:"36px",width:"100px"}}
                                      onClick={handleVideoClick}
                                    >
                                      فيديو{" "}
                                      <i
                                        className="fa-regular fa-circle-play"
                                        style={{ color: "#fff" }}
                                      ></i>
                                    </button>
                                    <Link to={`/explanation`}>
                                      <button
                                        className="show_video_btn"
                                        style={{backgroundColor:"#F57D20",color:"#fff",borderRadius:"20px",border:"none",height:"36px",width:"100px"}}

                                      >
                                        شرح{" "}
                                        <i
                                          className="fa-regular fa-circle-play"
                                          style={{ color: "#fff" }}
                                        ></i>
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </Tab>
                  ) : (
                    <p>
                      {/* Access to this tab is restricted. Please ensure you have
                        the correct permissions. */}
                    </p>
                  )}

                  {approvedUser ? (
                    <Tab title=" دوسيات ودفاتر ">
                      <div>
                        <div className="container text-center">
                          {videosData.map((item, index) => (
                            <div
                              className="row "
                              key={item.id}
                              style={{
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                                margin: "1vh",
                                listStyleType: "none",
                              }}
                              onClick={() => handleClick(item.id)}
                            >
                              <div
                                className={`col-lg-6 col-md-6 col-sm-12 ${
                                  expandedItemId === item.id ? "mb-3" : ""
                                }`}
                              >
                                <div className="d-flex align-items-center p-3">
                                  <img
                                    src={quesicon}
                                    alt="quesicon"
                                    height={"25px"}
                                    width={"25px"}
                                  />
                                  <li style={{ cursor: "pointer" }}>
                                    دوسية 1{" "}
                                  </li>
                                </div>
                              </div>
                              {expandedItemId === item.id && (
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex">
                                    <button
                                      className="show_video_btn"
                                      onClick={handleVideoClick}
                                      style={{backgroundColor:"#833988",color:"#fff",borderRadius:"20px",border:"none",height:"36px",width:"100px"}}
                                    >
                                      تنزيل{" "}
                                      <i className="fa-solid fa-download"></i>
                                    </button>
                                    <Link to={`/explanation`}>
                                      <button
                                        className="show_video_btn"
                                      style={{backgroundColor:"#F57D20",color:"#fff",borderRadius:"20px",border:"none",height:"36px",width:"100px"}}

                                      >
                                        عرض{" "}
                                        <i className="fa-regular fa-eye"></i>
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </Tab>
                  ) : (
                    <p>
                      {/* Access to this tab is restricted. Please ensure you have
                        the correct permissions. */}
                    </p>
                  )}
                  {approvedUser ? (
                    <Tab title="اختبارات محوسبة">
                      <div>
                        <div className="container text-center">
                          {videosData.map((item, index) => (
                            <div
                              className="row "
                              key={item.id}
                              style={{
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                                margin: "1vh",
                                listStyleType: "none",
                              }}
                            >
                              <div className={`col-lg-6 col-md-6 col-sm-12`}>
                                <div className="d-flex align-items-center p-3">
                                  <li style={{ cursor: "pointer" }}>
                                    اختبار الموضوع الاول{" "}
                                  </li>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="d-flex justify-content-evenly">
                                  <div className="d-flex">
                                    <Link to={`/quiz`}>
                                      <button
                                        className="show_video_btn mt-2"
                                        onClick={handleVideoClick}
                                        style={{backgroundColor:"#F57D20",color:"#fff",borderRadius:"20px",border:"none",height:"36px",width:"100px"}}

                                      >
                                        ابدا الاختبار
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Tab>
                  ) : (
                    <p>
                      {/* Access to this tab is restricted. Please ensure you have
                        the correct permissions. */}
                    </p>
                  )}
                </Tabs>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* MiniPopUpLogin */}
      {showLoginPopup && (
        <MiniPopUpLogin
          title_popup={title_popup}
          description_popup={description_popup}
          show={showLoginPopup}
          onClose={handleClosePopup}
        />
      )}

      {ShowPopupConf && (
        <MiniPopUpConfirm
          title_popup_confirm={title_popup_confirm}
          description_popup_confirm={description_popup_confirm}
          smShow={smShow}
          onClose={handleClosePopupConf}
        />
      )}
      <VideoModel
        show={showPopupVideo}
        handleClose={handleClose}
        videoUrl={videoUrl}
      />
    </>
  );
}

export default QuestionDetails;
